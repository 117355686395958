import '../styles/main.scss';
document.querySelector('.navigation__items-toggle').addEventListener('click', () => {
    document.querySelector('.navigation__items').classList.toggle('navigation__items--active');
});
document.addEventListener('click', (event) => {
    const target = event.target;
    const navigationItem = target.closest('.navigation__item');
    if (target.classList.contains('navigation__dropdown-item')) {
        return;
    }
    if (!navigationItem) {
        document.querySelectorAll('.navigation__dropdown--active').forEach((element) => element.classList.remove('navigation__dropdown--active'));
        return;
    }
    event.preventDefault();
    const dropdown = navigationItem.querySelector('.navigation__dropdown');
    const dropdownActive = document.querySelector('.navigation__dropdown--active');
    if (dropdownActive && dropdownActive !== dropdown) {
        dropdownActive.classList.remove('navigation__dropdown--active');
    }
    if (target.classList.contains('navigation__link')) {
        dropdown.classList.toggle('navigation__dropdown--active');
    }
    dropdown.classList.add('navigation__dropdown--active');
});
document.addEventListener('click', (event) => {
    if (!event.target.closest('.navigation')) {
        document.querySelector('.navigation__items')?.classList.remove('navigation__items--active');
    }
});
document.addEventListener('keyup', (event) => {
    if ('Escape' === event.key) {
        document.querySelector('.navigation__items--active')?.classList.remove('navigation__items--active');
        document.querySelectorAll('.navigation__dropdown--active').forEach((element) => element.classList.remove('navigation__dropdown--active'));
    }
});
